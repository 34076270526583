<script>
// import { purchaseService } from "../../../helpers/backend/purchase.service"
import { supplierService } from "@/helpers/backend/supplier.service"
import { receiveService } from "@/helpers/backend/receive.service"
import { warehouseService } from "@/helpers/backend/warehouse.service"
import { productService } from "@/helpers/backend/product.service"

// import ReceiveList from "./receive/list.vue"
import ProductForm from "./product/form.vue"
import ProductList from "./product/list.vue"

export default {
  components: {
    // ReceiveList
    ProductForm,
    ProductList
  },
  data() {
    return {
      form: {
        id: null,
        // invoice_number: "",
        // po: "",
        number: null,
        supplier_id: null,
        warehouse_id: null,
        status: null,
        products: [],
        // receives: []
      },
      product_options: [{
        text: "Select One",
        value: null,
        data: null,
      }],
      supplier_options: [{ text: "Select One", value: null }],
      warehouse_options: [{ text: "Select One", value: null }],
      status_options: [{ text: "Select One", value: null }, { text: "Pending", value: "pending" }, { text: "Received", value: "received" }, { text: "Canceled", value: "canceled" }],
      busy: false,
      show: true,
      showDismissibleAlert: false,
      error: "",
      active: false,
    }
  },
  created() {
    this.fetchData()
  },
  computed: {
    isUpdateState() {
      return this.form.id != null
    },
  },
  methods: {
    fetchData() {
      supplierService.fetch().then(
        (data) => {
          this.supplier_options = this.supplier_options.concat(
            data.suppliers.map((item) => {
              return { text: item.name, value: item.id }
            })
          )
        },
        (err) => {
          this.error =
            "Failed to fetch supplier data. Error : " +
            err +
            "  Dissmiss to refresh"
          this.busy = false
          this.showDismissibleAlert = true
        }
      )
      warehouseService.getAll().then(
        (data) => {
          this.warehouse_options = this.warehouse_options.concat(
            data.warehouses.map((item) => {
              return { text: item.name, value: item.id }
            })
          )
        },
        (err) => {
          this.error =
            "Failed to fetch warehouse data. Error : " +
            err +
            "  Dissmiss to refresh"
          this.busy = false
          this.showDismissibleAlert = true
        }
      )
      productService.getAllProduct().then(
        (data) => {
          this.product_options = this.product_options.concat(
            data.products.map((item) => {
              return { text: item.name, value: item.id, data: item }
            })
          )
        },
        (err) => {
          this.showDismissibleAlert = true
          this.error = "Failed to get products. Error : " + err
        }
      )
    },
    onSubmit(event) {
      event.preventDefault()
      this.busy = true
      receiveService.save(this.form).then(
        (data) => {
          if (data != null) {
            this.reset()
            this.$emit("refresh")
            this.showDismissibleAlert = true
            this.error = ""
            this.setActive(false)
          }
          this.busy = false
        },
        (error) => {
          this.showDismissibleAlert = true
          this.error =
            "Failed to Save Receive. Error : " + error
          this.busy = false
        }
      )
    },
    // setPosition(position) {
    //   this.form.coordinate = position
    // },
    reset() {
      // Reset our form values
      this.form.id = null
      // this.form.invoice_number = ""
      // this.form.po = ""
      this.form.supplier_id = null
      this.form.warehouse_id = null
      this.form.number = null
      this.form.status = ""
      this.form.id = null
      this.form.products = []
      
      // Trick to reset/clear native browser form validation state
      this.show = false
      this.showDismissibleAlert = false
      this.error = ""
      this.$nextTick(() => {
        this.show = true
      })
    },
    onReset(event) {
      event.preventDefault()
      this.setActive(false)
      this.reset()
    },
    setActive(b, outside_triger) {
      this.active = b
      if (outside_triger) return
      this.$emit("active_change", b)
    },
    initUpdate(id) {
      this.setActive(true)

      this.$nextTick(() => {
        this.reset()
        this.$refs.receive.$el.focus()
        this.busy = true
        this.form.id = id
        receiveService.fetch(id).then(
          (data) => {
            if (data.receive != null) {
              this.form.id = data.receive.id
              this.form.number = data.receive.number
              this.form.supplier_id = data.receive.supplier_id
              this.form.warehouse_id = data.receive.warehouse_id
              this.form.status = data.receive.status
              this.form.products = data.receive.products.map(item => {
                return {
                  ...item,
                  product_name: item.product.name,
                  product_sku: item.product.sku,
                }
              })
            } else {
              this.showDismissibleAlert = true
              this.error =
                "Failed to get receive to update. Error : receive not found"
            }
            this.busy = false
          },
          (err) => {
            this.error =
              "Failed to get receive type to update. Error : " + err
            this.busy = false
            this.showDismissibleAlert = true
          }
        )
      })
    },
    invokeCreate() {
      this.setActive(true)
    },
    onProductSubmit(product) {
      // Search if product exist
      let foundIdx = this.form.products.findIndex(item => item.product_id == product.product_id)
      if (foundIdx == -1) {
        this.form.products.push(product)
      } else {
        this.$set(this.form.products, foundIdx, product)
      }
    },
    onProductEdit(product) {
      this.$refs['product-form'].initUpdate(product)
    },
    onProductDelete(id) {
      console.log("ID : " + id)
      this.form.products = this.form.products.filter(item => item.product_id !== id)
    },
  },
}
</script>


<template>
  <div v-if="this.active">
    <b-alert
      :variant="error == '' ? 'success' : 'danger'"
      dismissible
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
    >
      <div v-if="error != ''">
        {{ error }}
      </div>
      <div v-else>Success perform Insert/Update action</div>
    </b-alert>
    <div class="card">
      <div class="card-body">
        <div>
          <b-form @submit="onSubmit" @reset="onReset" v-if="show" ref="form">
            <b-overlay :show="busy" rounded="lg" opacity="0.6">
              <!-- <b-form-group
                id="input-group-invoice"
                label="Invoice Number :"
                label-for="input-invoice"
              >
                <b-form-input
                  id="input-invoice"
                  v-model="form.invoice_number"
                  type="text"
                  placeholder="Invoice Number"
                  required
                  ref="invoice"
                ></b-form-input>
              </b-form-group> -->

              <!-- <b-form-group
                id="input-group-po"
                label="PO :"
                label-for="input-po"
              >
                <b-form-input
                  id="input-po"
                  v-model="form.po"
                  type="text"
                  placeholder="PO Number"
                  required
                  ref="po"
                ></b-form-input>
              </b-form-group> -->

              <b-form-group
                id="input-group-receive"
                label="Receive :"
                label-for="input-receive"
              >
                <b-form-input
                  id="input-receive"
                  v-model="form.number"
                  type="text"
                  placeholder="Receive Number"
                  required
                  ref="receive"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="input-group-supplier"
                label="Supplier : "
                label-for="input-supplier"
              >
                <b-form-select
                  id="input-supplier"
                  v-model="form.supplier_id"
                  :options="supplier_options"
                  required
                ></b-form-select>
              </b-form-group>

              <b-form-group
                id="input-group-warehouse"
                label="Warehouse : "
                label-for="input-warehouse"
              >
                <b-form-select
                  id="input-warehouse"
                  v-model="form.warehouse_id"
                  :options="warehouse_options"
                  required
                ></b-form-select>
              </b-form-group>

              <b-form-group
                id="input-group-status"
                label="Status : "
                label-for="input-status"
              >
                <b-form-select
                  id="input-status"
                  v-model="form.status"
                  :options="status_options"
                  required
                ></b-form-select>
              </b-form-group>
<!-- 
              <template v-if="form.id">
                <router-link
                  :to="{
                    name: 'purchase.receive',
                    params: form,
                  }"
                >
                  <b-button type="reset" variant="primary">
                    Add Receive
                  </b-button>
                </router-link>

              </template> -->

              <ProductForm
                class="mt-2"
                ref="product-form"
                :product_options="product_options"
                @submit="onProductSubmit"
              />

              <ProductList
                class="mt-2"
                ref="product-list"
                :data="form.products"
                @edit="onProductEdit"
                @delete="onProductDelete"
              />

              <b-button class="mr-2" type="submit" variant="primary">
                {{ isUpdateState ? "Update" : "Submit" }}
              </b-button>
              <b-button type="reset" variant="danger">Cancel</b-button>
            </b-overlay>
          </b-form>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <b-form-group>
      <b-button variant="primary" @click="invokeCreate">Create new</b-button>
    </b-form-group>
  </div>
</template>



