import { authHeader } from './auth-header'
import axiosInstance from "./client"

export const receiveService = {
  fetch,
  save,
  del,
}

function fetch(id) {
  let url = '/api/v1/user/receive'
  if (id) {
    url += '/' + id
  }
  const requestOptions = {
    url: url,
    method: 'GET',
    headers: authHeader()
  }
  return axiosInstance(requestOptions)
}

function save(data) {
  const method = data["id"] != null ? "PUT" : "POST"
  const requestOptions = {
    url: `/api/v1/user/receive`,
    method: method,
    headers: { 'Content-Type': 'application/json' },
    data: JSON.stringify(data)
  }

  return axiosInstance(requestOptions)
}


function del(id) {
  const requestOptions = {
    url: `/api/v1/user/receive/` + id,
    method: 'DELETE',
    headers: authHeader()
  }
  return axiosInstance(requestOptions)
}

