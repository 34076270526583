<script>
import { dateMixin } from '@/helpers/date'
import { receiveService } from "@/helpers/backend/receive.service"

/**
 * Transactions component
 */
export default {
  mixins: [dateMixin],
  data() {
    return {
      data: [],
      loading: false,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50],
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: false,
      fields: [
        { key: "id", sortable: true, label: "Receive ID" },
        { key: "number", sortable: true, label: "Receive Number" },
        { key: "supplier", sortable: true, label: "Supplier" },
        { key: "warehouse", sortable: true, label: "Warehouse" },
        { key: "status", sortable: true, label: "Status" },
        { key: "created_at", sortable: true, label: "Date" },
        { key: "updated_at", sortable: true, label: "Last Update" },
        { key: "action" }
      ],
      showDismissibleAlert: false,
      active: true,
      error: "",
    }
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.data.length
    }
  },
  created() {
    this.fetchData()
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.data.length
  },
  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    setActive(b) {
      this.active = b
    },
    fetchData() {
      this.error = null
      this.loading = true
      this.showDismissibleAlert = false
      //const fetchedId = this.$route.params.id
      receiveService.fetch().then(
        data => {
          this.data = data.receives
          this.loading = false
        },
        err => {
          this.error = "Failed to fetch receive data. Error : " + err + "  Dissmiss to refresh"
          this.loading = false
          this.showDismissibleAlert = true
        }
      )
    },
    onDelete(id) {
      console.log(id)
      if (confirm("Do you really want to delete this instance, this action can't be undone?")) {
        this.busy = true
        receiveService.del(id).then(
          () => {
            this.fetchData()
            this.busy = false
          },
          err => {
            this.error = "Failed to delete receive. Error : " + err
            this.showDismissibleAlert = true
          }
        )
      }
    },
    onUpdate(id) {
      this.$emit('onEdit', id)
    }
  }
};
</script>

<template>
  <div v-if="this.active">
    <b-alert
      variant="danger"
      dismissible
      fade
      :show="showDismissibleAlert"
      @dismissed="fetchData"
    >
      {{ error }}
    </b-alert>
    <div class="card">
      <div class="card-body">
        <div class="row mt-4">
          <div class="col-sm-12 col-md-6">
            <div id="tickets-table_length" class="dataTables_length">
              <label class="d-inline-flex align-items-center">
                Show&nbsp;
                <b-form-select
                  v-model="perPage"
                  size="sm"
                  :options="pageOptions"
                ></b-form-select
                >&nbsp;entries
              </label>
            </div>
          </div>
          <!-- Search -->
          <div class="col-sm-12 col-md-6">
            <div
              id="tickets-table_filter"
              class="dataTables_filter text-md-right"
            >
              <label class="d-inline-flex align-items-center">
                Search:
                <b-form-input
                  v-model="filter"
                  type="search"
                  class="form-control form-control-sm ml-2"
                ></b-form-input>
              </label>
            </div>
          </div>
          <!-- End search -->
        </div>
        <!-- End search -->
      </div>
      <div class="table-responsive">
        <b-table
          :items="data"
          :fields="fields"
          responsive="sm"
          :per-page="perPage"
          :current-page="currentPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
        >
          <template v-slot:cell(supplier)="row">
            {{ row.value.name }}
          </template>
          <template v-slot:cell(warehouse)="row">
            {{ row.value.name }}
          </template>
          <template v-slot:cell(status)="row">
              <div
                class="badge font-size-12"
                :class="{
                  'badge-success': row.item.status == 'received',
                  'badge-warning': row.item.status == 'pending',
                  'badge-danger': row.item.status == 'canceled',
                }"
              >
                {{ row.item.status }}
              </div>
            </template>
          <template v-slot:cell(created_at)="row">
            {{ row.item.created_at==null? "" : new Date(row.item.created_at).toDateString() + " " + new Date(row.item.created_at).toLocaleTimeString() }}
          </template>
          <template v-slot:cell(updated_at)="row">
            {{ row.item.updated_at==null? "" : new Date(row.item.updated_at).toDateString() + " " + new Date(row.item.updated_at).toLocaleTimeString() }}
          </template>
          <template v-slot:cell(action)="row">
            <a
              @click="onUpdate(row.item.id)"
              class="mr-3 text-primary"
              v-b-tooltip.hover
              data-toggle="tooltip"
              title="Edit"
            >
              <i class="mdi mdi-pencil font-size-18"></i>
            </a>
            <!-- <router-link
              :to="{
                name: 'purchase.receive',
                params: row.item
              }"
              class="mr-3 text-primary"
              v-b-tooltip.hover
              data-toggle="tooltip"
              title="View Detail"
            >
              <i class="mdi mdi-eye font-size-18"></i>
            </router-link> -->
            <a @click="onDelete(row.item.id)" class="text-danger" v-b-tooltip.hover title="Delete">
              <i class="mdi mdi-trash-can font-size-18"></i>
            </a>
          </template>
        </b-table>
      </div>
      <div class="row">
        <div class="col">
          <div class="dataTables_paginate paging_simple_numbers float-right">
            <ul class="pagination pagination-rounded mb-0">
              <!-- pagination -->
              <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>