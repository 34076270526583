<script>
export default {
  props: {
    product_options: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      form: {
        product_id: null,
        product_sku: null,
        cost: null,
        case: null,
        unit_per_case: null,
        discount: null,
      },
      busy: false,
      show: true,
      showDismissibleAlert: false,
      error: "",
      active: false,
    }
  },
  computed: {
    isUpdateState() {
      return this.form.id != null
    },
    productUnit() {
      return this.form.unit_per_case * this.form.case
    },
    productFinalCost() {
      return this.form.cost * (100 - this.form.discount) / 100
    }
  },
  methods: {
    onSubmit(event) {
      event.preventDefault()
      // Copy form
      this.$emit("submit", { ...this.form })
      this.reset()
    },
    reset() {
      // Reset our form values
      this.form.product_id = null
      this.form.product_sku = null
      this.form.cost = null
      this.form.unit = null
      this.form.case = null
      this.form.unit_per_case = null
      this.form.discount = null
      // Trick to reset/clear native browser form validation state
      this.show = false
      this.showDismissibleAlert = false
      this.error = ""
      this.$nextTick(() => {
        this.show = true
        this.setActive(false)
      })
    },
    onReset(event) {
      event.preventDefault()
      this.reset()
    },
    initUpdate(product) {
      this.setActive(true)

      this.form = product
    },
    setActive(b, outside_triger) {
      this.active = b
      if (outside_triger) return
      this.$emit("active_change", b)
    },
    invokeCreate() {
      this.setActive(true)
    }
  },
  watch: {
    'form.product_id': function (val) {
      if (val) {
        const product = this.product_options.find(item => item.value == val)
        this.form.product_sku = product.data.sku
        this.form.product_name = product.data.name
      }
    }
  }
}
</script>

<template>
  <div v-if="active">
    <b-alert
      :variant="error == '' ? 'success' : 'danger'"
      dismissible
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
    >
      <div v-if="error != ''">
        {{ error }}
      </div>
      <div v-else>Success perform Insert/Update action</div>
    </b-alert>
    <div class="card">
      <div class="card-body">
        <div>
          <b-form @submit="onSubmit" @reset="onReset" v-if="show" ref="form">
            <b-overlay :show="busy" rounded="lg" opacity="0.6">
              <b-form-group
                id="input-group-product"
                label="Product :"
                label-for="input-product"
              >
                <b-form-select v-model="form.product_id" required>
                  <b-form-select-option
                    v-for="option in product_options"
                    :key="option.value"
                    :value="option.value"
                  >
                    {{ option.text }}
                  </b-form-select-option>
                </b-form-select>
              </b-form-group>
              <b-row>
                <b-col>
                  <b-form-group
                    id="input-group-cost"
                    label="Cost per Unit :"
                    label-for="input-cost"
                  >
                    <b-form-input
                      id="input-cost"
                      v-model.number="form.cost"
                      placeholder="Unit Cost"
                      type="number"
                      required
                    />
                  </b-form-group>
                </b-col>
                <!-- <b-col>
                  <b-form-group
                    id="input-group-discount"
                    label="Discount :"
                    label-for="input-discount"
                  >
                    <b-form-input
                      id="input-discount"
                      v-model.number="form.discount"
                      placeholder="Discount"
                      type="number"
                      required
                    />
                  </b-form-group>
                </b-col> -->
                <!-- <b-col>
                  <b-form-group
                    id="input-group-final"
                    label="Final :"
                    label-for="input-final"
                  >
                    <b-form-input
                      id="input-final"
                      :value="productFinalCost"
                      placeholder="Final"
                      type="number"
                      step="any"
                      readonly
                      required
                    />
                  </b-form-group>
                </b-col> -->
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group
                    id="input-group-case"
                    label="Case :"
                    label-for="input-case"
                  >
                    <b-form-input
                      id="input-case"
                      v-model.number="form.case"
                      placeholder="Product Case"
                      type="number"
                      required
                    />
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group
                    id="input-group-unit-case"
                    label="Unit / Case :"
                    label-for="input-unit-case"
                  >
                    <b-form-input
                      id="input-unit-case"
                      v-model.number="form.unit_per_case"
                      placeholder="Unit / Case"
                      type="number"
                      required
                    />
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group
                    id="input-group-unit"
                    label="Total Unit :"
                    label-for="input-unit"
                  >
                    <b-form-input
                      id="input-unit"
                      :value="productUnit"
                      placeholder="Product unit"
                      type="number"
                      readonly
                      required
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-button class="mr-2" type="submit" variant="primary"
                >Submit</b-button
              >
              <b-button type="reset" variant="danger">Cancel</b-button>
            </b-overlay>
          </b-form>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <b-form-group>
      <b-button variant="primary" @click="invokeCreate"
        >Add new product</b-button
      >
    </b-form-group>
  </div>
</template>



