<script>
import { dateMixin } from '@/helpers/date'
/**
 * Transactions component
 */
export default {
  mixins: [dateMixin],
  props: {
    data: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      loading: false,
      sortBy: "ID",
      sortDesc: false,
      fields: [
        { key: "product_sku", sortable: true, label: "SKU" },
        { key: "product_name", sortable: true, label: "Name" },
        { key: "unit", sortable: true, label: "Total Unit" },
        { key: "cost", sortable: true, label: "Cost per Unit" },
        { key: "action" }
      ],
      showDismissibleAlert: false,
      error: "",
    }
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.data.length
    }
  },
  methods: {
    onDelete(id) {
      if (confirm("Do you really want to delete this instance, this action can't be undone?")) {
        this.$emit('delete', id)
      }
    },
    onUpdate(product) {
      this.$emit('edit', { ...product })
    }
  }
}
</script>

<template>
  <div class="card">
    <div class="card-body">
      <div class="table-responsive">
        <b-table
          :items="data"
          :fields="fields"
          responsive="sm"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
        >
          <template v-slot:cell(unit)="row">
            {{ row.item.unit_per_case * row.item.case }}
          </template>
          <template v-slot:cell(cost)="row">
            {{ (row.item.cost * (100 - row.item.discount)) / 100 }}
          </template>
          <template v-slot:cell(action)="row">
            <a
              @click="onUpdate(row.item)"
              class="mr-2 text-primary"
              v-b-tooltip.hover
              data-toggle="tooltip"
              title="Edit"
            >
              <i class="mdi mdi-pencil font-size-18"></i>
            </a>
            <a
              @click="onDelete(row.item.product_id)"
              class="text-danger"
              v-b-tooltip.hover
              title="Delete"
            >
              <i class="mdi mdi-trash-can font-size-18"></i>
            </a>
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>